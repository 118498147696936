.content {
    overflow: auto;
    background: #fff;
}

.closebtn {
    margin-right: 1.5rem;
}

.layout {
    margin: .25rem;
}