.headerwrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: .25rem .25rem .25rem .25rem;
    background: #fff;
    border-radius: 4px;
    flex-wrap: wrap;
}

.headertitle {
    color: @primary-color;
    font-size: 1rem;
    margin-right: 1rem;
    font-weight: 500;
    text-transform: uppercase;
}

.subtitle {
    color: #00000073;
    font-size: .7rem;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";