.header {
    margin-bottom: 10px;
}

.icon {
    font-size: 25px;
}

.title {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 500;
}

.message {
    margin-left: 15px;
    font-size: 15px;
}

.footer {
    padding: 10px 16px;
    text-align: right;
}