.sider {
    background: @menu-dark-bg;
}

.siderContent {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
}

.siderAppMenu {
    height: 100%;
}

.userProfileMenu {
    padding-bottom: 1rem;
}

.menuItem {
    font-size: 1rem;
    font-weight: 500;
}

.avatar {
    background: @primary-color;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";