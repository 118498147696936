.tokenform {
    text-align: center;
}

.tokenformitem {
    width: 15rem;
    margin: auto;
}

.button {
    margin-top: 1rem;
}
