.validationsummary {
    overflow-x: hidden;
    height: 100%;
}

.error:hover{
    border-color: red;
    cursor: pointer;
}

.warning:hover{
    border-color: #CEB973;
    cursor: pointer;
}

.closeicon {
    color: rgba(0,0,0,.45);
}

.closeicon:hover {
    color: black;
    cursor: pointer;
}