.item {
    margin-bottom: 8px;
}

.switch {
    margin-bottom: 3px;
}

.label {
    width: 100px;
    margin-right: 8px;
}

.formitemcontainer {
    display: flex;
    align-items: baseline;
}