.pagecontainer {
    margin: .25rem;
}

.page {
    background: #fff;
}

.pagetitle {
    color: @primary-color;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";