html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

body {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-word;
}

.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  height: auto !important;
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selection-item-content {
  white-space: normal;
  word-break: break-word;
}

.ant-select-item-option-content {
  white-space: normal !important;
  word-break: break-word !important;
}

.ql-editing{
  left: 50px !important;
  top: 129.5px !important;
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before
{
    font-family: "Arial", sans-serif;
    content: "Arial" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before
{
    font-family: "Verdana", sans-serif;
    content: "Verdana" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Tahoma"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Tahoma"]::before
{
    font-family: "Tahoma", sans-serif;
    content: "Tahoma" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before
{
    font-family: "Georgia", serif;
    content: "Georgia" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Garamond"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Garamond"]::before
{
    font-family: "Garamond", serif;
    content: "Garamond" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="CourierNew"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="CourierNew"]::before
{
    font-family: "Courier New", monospace;
    content: "Courier New" !important;
}

.ql-font-Arial {
  font-family: "Arial", sans-serif;
}
.ql-font-Verdana {
  font-family: "Verdana", sans-serif;
}
.ql-font-Tahoma {
  font-family: "Tahoma", sans-serif;
}
.ql-font-Georgia {
  font-family: "Georgia", serif;
}
.ql-font-CourierNew {
  font-family: "Courier New", monospace;
}