.toolbox {
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
    padding: .25rem;
    margin: .25rem;
}

.settings {
    width: 30% !important;
    min-width: 30% !important;
    max-width: 30% !important;
    padding: .25rem;
    margin: .25rem;
}

.validationErrors {
    width: 25% !important;
    min-width: 25% !important;
    max-width: 25% !important;
    padding: 0 .5rem 0 .5rem;
}

.templatetitle {
    color: @primary-color;
    font-size: 1rem;
    font-weight: 500;
    width: 30rem;
}

.content {
    margin: .25rem;
}

@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";