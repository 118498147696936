.questiontitle {
    color: @primary-color;
    font-size: 1.7rem;
    margin-top: 0.5rem;
}

.questiondescription {
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.inputcomment {
    margin: 0.3rem;
}

.radio {
    display: flex;
    align-items: center;
}

.radio:hover {
    background-color: #f5f5f5;
}

.errormessage {
    color: #ff4d4f;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";