.question {
    margin-bottom: 16px;
    background: #f0f2f5;
}

.questionpageheader {
    padding: 0;
}

.titleinput {
    font-weight: 600;
}

.options {
    flex-flow: row;
    display: flex;
    padding-top: .5rem;
}

.subquestions {
    flex-flow: row;
    display: flex;
    padding-top: .5rem;
}

.buttons {
    margin-top: .5rem;
}

.icons {
    padding: .5rem;
}

.space {
    width: 100%;
}

.allowComment {
    padding-left: .5rem;
}

.questionpostitle {
    color: @primary-color;
    font-size: 1rem;
    margin-right: 1rem;
    font-weight: 500;
}

.warning {
    margin-bottom: .5rem;
}

.questionoptional {
    margin-bottom: 0;
}

.switch {
    margin-bottom: 3px;
}

.forminput {
    margin-bottom: 0;
    width: 500px;
}

.formitem {
    margin-bottom: 0;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";