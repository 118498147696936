.wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    padding: .5rem 0;
}

.header {
    display: flex;
    flex: 1;
    align-items: center;
}

.headertitle {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    margin-right: .25rem;
    width: 100%;
}

.headerextra {
    width: 100%;
}

.headerextra span {
    margin: .25rem;
}

.commands {
    flex: 1 1 auto;
    text-align: right;
}

.commands > * {
    margin: .25rem;
}

.commands > *:first-child {
    margin-top: 0px;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";