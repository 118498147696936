.connectors {
    display: block;
}

.condition {
    flex-flow: row;
    display: flex;
    padding-top: .5rem;
    vertical-align: middle;
    width: 100%;
}

.conditionitem {
    width: 100%;
    margin-right: 4px;
}

.icons {
    margin: 4px;
}