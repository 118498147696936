.details {
    height: 100%;
    width: 100%;
    display: flex;
}

.surveydetails {
    width: 100%;
    margin: 0 0.5rem 0 0;
}

.mailbody {
    max-height: 300px;
    overflow: auto;
}