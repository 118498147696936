.mappingoption {
    flex-flow: row;
    display: flex;
    padding-top: .5rem;
    vertical-align: middle;
}

.propertiesselect {
    width: 100%;
}

.switch {
    margin-bottom: 3px;
}