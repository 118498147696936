.templates {
    background: #fff;
    padding: 0.25rem;
    margin: 0.25rem;
    overflow-x: hidden;
    height: 100%;
} 

.listicon {
    background: #fff;
    color: @primary-color;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";