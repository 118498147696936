.formbox {
    background: #fff;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    width: 100%;
    padding: 12px;
    border-radius: 0 0 2px 2px;
    border: 1px solid #ebedf0;
    margin-top: 1rem;
    min-width: 0;
    min-height: 0;
  }
  
  .formbox_header {
    position: absolute;
    top: -14px;
    margin-left: -8px;
    padding: 1px 8px;
    color: #777;
    background: #fff;
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
  }
  
  .formbox_header_icon {
    width: 5%;
    margin-left: 5px;
  }
  
  .formbox_header_link {
    margin-left: 0.25rem;
  }
  
  .formbox_content {
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
  }
  
  .formbox_content_flex {
    display: flex;
    flex-flow: column nowrap;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
  }
  