.content {
    overflow-x: hidden;
    height: 100%;
} 

.flexContainer {
    display: flex;
    min-height: 500px;
}

.flexColumn {
    flex-flow: row;
}

.layout {
    margin: .25rem;
}