.properties {
    overflow-x: hidden;
    height: 100%;
}

.propertiesselect {
    width: 100%;
}

.switch {
    margin-bottom: 3px;
}

.formitem {
    margin-bottom: 0;
}