.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    line-height: inherit;
    z-index: 1000;
    background-color: @menu-dark-bg;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    padding-left: .5rem;
    padding-right: .5rem;
  }
  
  .headertitle {
    font-size: 1.5rem;
    text-transform: uppercase;
    display: flex;
  }
  
  .headerimage {
    height: 40px;
  }
  
.headerinfo{
  padding: .25rem;
}

  .headermenu {
    border-bottom: none;
    background-color: #fff !important;
  }
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";