.placeholder {
    display: flex;
}

.placeholderselect {
    width: 100%;
    margin-right: .5rem;
}

.mailbody {
    max-height: 300px;
    overflow: auto;
}