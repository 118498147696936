.form
{
    padding: 0px 10px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.input{
    width: 30em;
}
.buttonCancel
{
    margin-right: 5px;
}