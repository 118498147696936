.surveypagetitle {
    color: @primary-color;
    font-size: 2rem;
    margin: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.surveyquestions {
    padding: 0 20%;
}

.surveyquestionsmobile {
    padding: 0 5%;
}

.surveybuttons {
    text-align: center;
    padding-bottom: 1.5rem;
}

.surveybutton {
    margin: 0.2rem;
}
@hack: true; @import "F:\TFSBuildSocial1Data\5\s\webclient\theme.less";